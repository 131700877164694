import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import {
    Heading,
    Image,
    Card,
    CardBody,
    Button,
    Layer,
    Paragraph
} from 'grommet';

import {
    dismissPreviewDialog,
    showStep3Dialog,
    showTxSuccessDialog,
    showTxRejectedDialog,
    showCatchErrorDialog
} from '../../redux/appSice'

import {
    GAS_PER_TRANSACTION
} from '../../authmeConstants'

import {
    trackGA
}
from '../../utils/miscUtils'

const styles = theme => ({})

class PreviewDialog extends Component {
    state = {}

    onMintClicked = async () => {
        // this.props.mintStory()

        const seedTxt = this.props.app.seedTxt
        const inferTxt = this.props.app.inferTxt
        const price = this.props.supplyData._mintPrice 
        const account = this.props.account

        // console.log('minting', seedTxt, inferTxt, price, account)

        try {
            await this.props.authorMeERC721Contract.methods.mint(seedTxt, inferTxt)
                .send({
                    from: account,
                    value: price
                })
                .on('transactionHash', (hash) => {
                    console.log(`mint transactionHash: ${hash}`)

                    this.props.showStep3Dialog(hash)
                    trackGA('Web3', 'Tx-Pending')
                })
                .on('error', (err) => {
                    console.log('mint error:', err)
                })
                .then((result) => {
                    console.log('got mint result:', result)
                    this.props.showTxSuccessDialog()
                    trackGA('Web3', 'Web3-Mint-Success')
                })
        }
        catch(err) {
            if(err.code === 4001) {
                this.props.showTxRejectedDialog()
                trackGA('Web3', 'Web3-Error-UserRejectedTx')
            }
            else {
                this.props.showCatchErrorDialog()
                trackGA('Web3', `Web3-Catch-Error: ${err.code}`)
            }
        }
    }

    render() {
        // const { classes } = this.props

        let { seedTxt, inferTxt} = this.props.app
        // console.log(seedTxt)
        // console.log(inferTxt)

        let svgParts = []
        svgParts[0] = '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 360 360" style="background-color: black;"><style>.textBase { color: white; font-family: serif; font-size: 13.5px; }</style><foreignObject x="5" y="5" width="350" height="350" class="textBase"><div xmlns="http://www.w3.org/1999/xhtml" ><strong>'
        svgParts[1] = seedTxt
        svgParts[2] = '</strong> '
        svgParts[3] = inferTxt
        svgParts[4] = '</div></foreignObject></svg>'
        let svgData = svgParts.join('')

        // console.log('window.web3', window.web3.utils)
        // console.log('this.props.supplyData._mintPrice', this.props.supplyData)

        let mintPrice = window.web3.utils.fromWei(this.props.supplyData._mintPrice, 'ether')
        
        // console.log('this.props.app.avgGasPriceGwei', this.props.app.avgGasPriceGwei)

        let weiPrice = this.props.app.fastGasPriceGwei * 1000000000
        // console.log('weiPrice', weiPrice)

        let gweiGasFees = weiPrice * GAS_PER_TRANSACTION
        // console.log('gweiGasFees', gweiGasFees)
        
        let gasEth = window.web3.utils.fromWei(gweiGasFees.toString(), 'ether')
        // console.log('gasEth', gasEth)

        let totalPrice = (parseFloat(mintPrice) + parseFloat(gasEth)).toFixed(4)
        
        const avgTxCost = 1000000
        // console.log('gasPrice:', this.props.gasPrice)

        const gweiGasPrice = window.web3.utils.fromWei(this.props.gasPrice, 'gwei')
        // console.log('gweiGasPrice:', gweiGasPrice)

        const ethGasPrice = window.web3.utils.fromWei(this.props.gasPrice)
        // console.log('ethGasPrice:', ethGasPrice)

        const weiGasCost = avgTxCost * this.props.gasPrice
        // console.log('weiGasCost:', weiGasCost)

        const ethGasCost = window.web3.utils.fromWei(weiGasCost.toString())
        // console.log('ethGasCost:', ethGasCost)

        return (
            <Layer modal background={{"opacity": 'strong'}}
            // onEsc={() => setShow(false)}
            // onClickOutside={() => setShow(false)}
            >
                <Card background="#000000" width='medium'>
                    <CardBody pad="small">
                        <Heading level="3">Step 2: Preview & Mint</Heading>
                        <Image
                            src={'data:image/svg+xml;utf8,' + svgData}
                        />
                        {/* <Heading level="3">Step 3: Mint</Heading> */}
                        <Button primary label={`Mint Story ~${totalPrice} ETH`} color='accent-1' onClick={ this.onMintClicked}/>
                        <Paragraph margin="small" size="small" textAlign='center' alignSelf='center' color='dark-5'>
                            { `${mintPrice} mint fee + ~${gasEth} est. gas fee (@${gweiGasPrice} gwei price) = ${ethGasCost} ETH` }
                        </Paragraph>
                        <Paragraph margin="small" size="small" textAlign='center' alignSelf='center' color='dark-4'>
                        <b>Why the high gas fee? </b>
                            Unlike most NFTs, AuthorMe tokens are 100% on-chain. To achieve this the entire story is written to the blockchain which is a gas-intensive operation.
                        </Paragraph>
                        <Button primary={false}
                            size='medium'
                            label='Cancel'
                            color='dark-1'
                            onClick={() => this.props.dismissPreviewDialog()}/>
                    </CardBody>
                </Card>
            </Layer>
        )
    }
}

const mapStateToProps = state => ({
    // pathname: state.router.location.pathname,
    app: state.app,
})
    
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dismissPreviewDialog,
    showStep3Dialog,
    showTxSuccessDialog,
    showTxRejectedDialog,
    showCatchErrorDialog
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(PreviewDialog))