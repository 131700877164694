import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import {
    Box,
    Heading,
    Paragraph,
    Button,
    Layer,
    Spinner,
    Anchor
} from 'grommet';

import {
    dismissDialog
} from '../../redux/appSice'

import {
    ETHERSCAN_TX_BASE_URL
} from '../../authmeConstants'

const styles = theme => ({})

class StatusDialog extends Component {
    state = {}

    render() {
        // const { classes } = this.props

        return (
            <Layer modal
            // onEsc={() => setShow(false)}
            // onClickOutside={() => setShow(false)}
            >
                <Heading margin="medium" level="2">{ this.props.app.dialogTitle }</Heading>
                <Paragraph margin="medium">{ this.props.app.dialogText }</Paragraph>
                <Box align='center' margin='medium'>
                    {
                        this.props.app.txHash ?
                            <Anchor margin='medium' size='xsmall' href={ETHERSCAN_TX_BASE_URL + this.props.app.txHash} target='_blank' label={'View on Etherscan: ' + this.props.app.txHash.substr(0, 25) + '...'} />
                        :
                            null
                    }

                    {
                        this.props.app.showDialogSpiner ?
                            <Spinner size='medium' />
                        :
                            <Button primary label="Close" color='status-error' onClick={ ()=> { this.props.dismissDialog() }} />
                    }
                </Box>        
            </Layer>
        )
    }
}

const mapStateToProps = state => ({
    // pathname: state.router.location.pathname,
    app: state.app,
})
    
const mapDispatchToProps = (dispatch) => bindActionCreators({
    dismissDialog
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(StatusDialog))