import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import ReactGA from 'react-ga'

import detectEthereumProvider from '@metamask/detect-provider'

import {
    Main,
    Grid,
    Box,
    Text,
    Grommet,
    Image,
    Paragraph,
    Button
} from 'grommet';

import DappControls from '../components/dappControls'
import Info from '../components/info'
import StatusDialog from '../components/statusDialog'
import PreviewDialog from '../components/previewDialog'

import {
    trackGA
}
from '../../utils/miscUtils'

import {
    REACT_GA_ENABLED,
    getBackgrondColor
} from '../../authmeConstants'

import AuthMeERC721 from '../../contracts/AuthMeERC721.json'

import {
    setGasPrice,
    showWeb3BrowserErrorDialog,
    showWeb3NetworkErrorDialog,
    showInputErrorDialog,
    showInputLengthError,
    showStep1Dialog,
    showStep2Dialog,
    showStep3Dialog,
    showTxSuccessDialog,
    showTxRejectedDialog,
    // showInferApiErrorDialog,
    // showInferNullResponseDialog,
    showCatchErrorDialog,
    dismissDialog
} from '../../redux/appSice'

import dotenv from 'dotenv'
dotenv.config()

const getWeb3Module = () => import('web3').then((module) => module.default);

const getEthEnabled = async () => {
    const Web3 = await getWeb3Module()
    if (window.ethereum) {
        await window.ethereum.send('eth_requestAccounts')
        window.web3 = new Web3(window.ethereum)
        return true;
    }
    return false
}

const styles = theme => ({})

let provider
let authorMeERC721Contract
let account
let supplyData
let gasPrice

class App extends Component {
    state = {
        bgColor: 'accent-4',
        supplyData: null
    }

    constructor(props) {
        super(props)

        this.state.bgColor = getBackgrondColor()
        
        if(REACT_GA_ENABLED) {
            ReactGA.initialize('UA-77905841-7', { debug: false })
            ReactGA.pageview('AppIndex');
        }

        // this.props.setGasPrice()
    }

    // async componentDidMount() {
    async connectWalletBtnClick() {
        let ethEnbled = await getEthEnabled()

        if(ethEnbled) {
            console.log('ethereum.networkVersion', window.ethereum.networkVersion)
            console.log('ethereum.selectedAddress', window.ethereum.selectedAddress)
        }
        else {
            console.log('ethEnbled', ethEnbled)
        }

        provider = await detectEthereumProvider()
        // console.log(provider)
        if (!provider) {
            this.props.showWeb3BrowserErrorDialog()
            trackGA('Web3', 'Web3-Provider-No-Provider')
            return
        }

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        account = accounts[0]
        console.log('account', account)

        const authMeERC721DeployedNetwork = AuthMeERC721.networks[window.ethereum.networkVersion]

        authorMeERC721Contract = await new window.web3.eth.Contract(
            AuthMeERC721.abi,
            authMeERC721DeployedNetwork && authMeERC721DeployedNetwork.address
        )

        if(!authMeERC721DeployedNetwork) {
            this.props.showWeb3NetworkErrorDialog()
            trackGA('Web3', 'Web3-Network-Mismatch')
            return
        }

        supplyData = await authorMeERC721Contract.methods.getSupplyData().call()
        if(supplyData) {
            this.setState({ supplyData: supplyData} )
            // console.log('got supplyData:', supplyData)
            
            trackGA('Web3', 'Web3-InitSuccess')
        }
        else {
            trackGA('Web3', 'Web3-InitFailed')
        }

        gasPrice = await window.web3.eth.getGasPrice()
        // console.log('gasPrice', gasPrice);
        this.setState({ gasPrice: gasPrice} )
    }

    render() {
        // const { classes } = this.props

        return (
            <Grommet full>
                <Main full background={this.state.bgColor}>
                    <Grid
                        rows={['small', 'xxsmall', supplyData ? 'small' : 'xsmall', supplyData ? 'medium' : 'xxsmall', 'medium', 'medium']}
                        columns={['full']}
                        gap="small"
                        areas={[
                            { name: 'dapp-title', start: [0, 0], end: [0, 0] },
                            { name: 'dapp-description', start: [0, 1], end: [0, 1] },
                            { name: 'dapp-input', start: [0, 2], end: [0, 2] },
                            { name: 'dapp-controls', start: [0, 3], end: [0, 3] },
                            { name: 'dapp-footer', start: [0, 4], end: [0, 4] }
                        ]}
                        pad='medium'>
                        
                        <Box align='center' gridArea="dapp-title" margin='small'>
                            <Image
                                fill="horizontal"
                                fit="contain"
                                src='author-me.png'
                            />
                        </Box>

                        <Box align='center' gridArea="dapp-description">
                            <Text
                                textAlign='center'
                                margin='xsmall'
                                weight='bold'>AI co-authored NFT Stories</Text>
                        </Box>
                        
                        {
                            supplyData ?
                                <DappControls
                                    provider={provider}
                                    authorMeERC721Contract={authorMeERC721Contract}
                                    account={account}
                                    supplyData={supplyData}/>
                            :
                                <Box align='center'>
                                    <Paragraph margin="small" size='large' textAlign='center'>
                                        A wallet connection is required to interact with AuthorMe
                                    </Paragraph>
                                    <Button primary size='large' label='Connect wallet' color='neutral-3' onClick={() => {this.connectWalletBtnClick()}}/>
                                </Box>
                        }

                        <Info/>
                        
                    </Grid>

                    {this.props.app.showStatusDialog && (
                        <StatusDialog/>
                    )}

                    {this.props.app.showPreviewDialog && (
                        <PreviewDialog
                            supplyData={supplyData}
                            authorMeERC721Contract={authorMeERC721Contract}
                            account={account}
                            gasPrice={gasPrice}/>
                    )}

                </Main>
                
            </Grommet>
        )
    }
}

// App.contextTypes = {
//     router: PropTypes.object,
//     store: PropTypes.object
// }
  
const mapStateToProps = state => ({
    // pathname: state.router.location.pathname,
    app: state.app,
})
    
const mapDispatchToProps = (dispatch) => bindActionCreators({
    // mint: () => dispatch({ type: 'MINT' }),
    setGasPrice,
    showWeb3BrowserErrorDialog,
    showWeb3NetworkErrorDialog,
    showInputErrorDialog,
    showInputLengthError,
    showStep1Dialog,
    showStep2Dialog,
    showStep3Dialog,
    showTxSuccessDialog,
    showTxRejectedDialog,
    // showInferApiErrorDialog,
    // showInferNullResponseDialog,
    showCatchErrorDialog,
    dismissDialog
}, dispatch)
    
    
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(App))
