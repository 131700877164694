import {
    INFER_TXT_LENGTH,
    INFERKIT_API,
    AUTHORME_API,
    DEFI_PULSE_API
} from '../authmeConstants'

const axios = require('axios')

export function getInferTxtFromAxios(seedText) {
    let postData = {
        "length": INFER_TXT_LENGTH,
        "forceNoEnd": true,
        "prompt": {
            "text": seedText
        }
    }
    
    return new Promise((resolve, reject) =>
        axios.post(INFERKIT_API, postData, {
            headers: { 'Authorization': 'Bearer 2994910e-d93d-4830-8097-faf12a20d8fc' }
          })
          .then((response) => {
            // console.log('response.data', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            // console.log('axios error', error)
            reject('failed')
        })
    );
}

export function getChatGptStoryViaApi(seedText, storyType) {
  console.log({seedText})
  console.log({storyType})
    return new Promise((resolve, reject) =>
        axios.post(`${AUTHORME_API}/chatgpt`, {
          'seedText': seedText,
          'storyType': storyType
        }, {
            headers: { 'x-api-key': 'shfK9yTZvw42odQZ1qtXZ8EE66p4yBhA2y3m9N4e' }
          })
          .then((response) => {
            // console.log('response.data', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            console.log('axios error', error)
            reject('failed')
        })
    );
}

export function getGasPrices() {
    return new Promise((resolve, reject) =>
        axios.get(DEFI_PULSE_API)
          .then((response) => {
            console.log('getGasPrice response.data', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            console.log('getGasPrice axios error', error)
            reject('failed')
        })
    );
}