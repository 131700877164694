import { configureStore } from '@reduxjs/toolkit'

import createSagaMiddleware from 'redux-saga'

import appReducer from '../redux/appSice'
// import counterReducer from '../features/counter/counterSlice'

import { mintSaga } from '../sagas/mintSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        app: appReducer,
        // counter: counterReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                // ignoredActions: [ 'app/initWeb3' ],
                // Ignore these field paths in all actions
                ignoredActionPaths: [
                    // 'payload.infuraWeb3',
                    // 'payload.web3',
                    // 'payload.monsterMobsERC721Contract',
                    // 'payload.supplyData'
                ],

                // Ignore these paths in the state
                ignoredPaths: [
                    // 'infura.web3',
                    // 'authenticatedWeb3.web3',
                    // 'infura.monsterMobsERC721Contract',
                    // 'authenticatedWeb3.monsterMobsERC721Contract'
                ],
            },
        }).concat(sagaMiddleware)
})

sagaMiddleware.run(mintSaga)
