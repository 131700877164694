import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import {
    Grid,
    Box,
    Paragraph,
    Anchor
} from 'grommet';

import {
    TWITTER_URL,
    DISCORD_URL,
    OPENSEA_URL
} from '../../authmeConstants'

const styles = theme => ({})

class Info extends Component {
    state = {}

    render() {
        // const { classes } = this.props

        return (
            <Box align='center' margin='small' gridArea="dapp-footer">
                <Paragraph margin="small" size='small' textAlign='center'>
                    NEWSFLASH: AuthorMe now runs on ChatGPT!!!
                </Paragraph>
                <Paragraph margin="small" size='small' textAlign='center'>
                    Generate unique AI micro stories and mint them as NFTs. Simply provide a start to you story (20 to 300 characters) and we'll do the rest.
                </Paragraph>
                <Paragraph margin="small" size='small' textAlign='center'>
                    We have zero control of the AI's output. Once your story has been generated you'll have the option to mint it to the Ethereum blockchain as an ERC721 token that is 100% on-chain and entirely immutable.
                </Paragraph>
                <Paragraph margin="small" size='small' textAlign='center'>
                    The OpenSea collection will allow explicit &amp; sensitive content, but please use your discretion and keep it classy! Highly offensive content will be subject to a token burn.
                </Paragraph>
                <Paragraph margin="small" size='small' textAlign='center'>
                    @#!& Gas fees! Transactions will be expensive. After-all, we're writing a story to the blockchain!
                </Paragraph>
                <Grid
                    rows={['xxsmall']}
                    columns={['xsmall', 'xsmall']}
                    gap="small"
                    areas={[
                        { name: 'footer-twitter', start: [0, 0], end: [1, 0] },
                        { name: 'footer-opensea', start: [1, 0], end: [2, 0] },
                        // { name: 'footer-discord', start: [2, 0], end: [3, 0] }
                    ]}
                    >
                    <Box align='center' alignSelf='center' gridArea="footer-twitter">
                        <Anchor size='small' href={TWITTER_URL} target='_blank' label="TWITTER" />
                    </Box>
                    <Box align='center' alignSelf='center' gridArea="footer-opensea">
                        <Anchor size='small' href={OPENSEA_URL} target='_blank' label="OPENSEA" />
                    </Box>
                    {/* <Box align='center' alignSelf='center' gridArea="footer-discord">
                        <Anchor size='small' href={DISCORD_URL} target='_blank' label="DISCORD" />
                    </Box> */}
                </Grid>
            </Box>
        )
    }
}

const mapStateToProps = state => ({
    // pathname: state.router.location.pathname,
    app: state.app,
})
    
const mapDispatchToProps = (dispatch) => bindActionCreators({
    // initWeb3,
    // infuraWeb3Slice,
    // dispatch
    // mint
    // mint: () => dispatch({ type: 'MINT' }),
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(Info))