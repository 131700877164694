import ReactGA from 'react-ga'

import {
    REACT_GA_ENABLED
} from '../authmeConstants.js'

export function trackGA(category, action) {
    if(REACT_GA_ENABLED) {
        ReactGA.event({
            category: category,
            action: action
        })
    }
}

export function cleanTextForXml(value) {
    value = value.split('&').join('&amp;')
    value = value.split('<').join('&lt;')
    value = value.split('>').join('&gt;')
    value = value.split('"').join('&quot;')
    return value
}
