import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    getInferTxtFromAxios,
    getChatGptStoryViaApi,
    getGasPrices
} from '../utils/axiosUtil'
// import { getStoryFromChatGpt } from '../utils/chatGptUtils'

// import {
//     INFER_TXT_LENGTH,
//     INFERKIT_API,
// } from '../authmeConstants'

import {
    trackGA,
    cleanTextForXml
}
from '../utils/miscUtils'

const initialState = {
    fastGasPriceGwei: 25,
    showStatusDialog: false,
    dialogTitle: '',
    dialogText: '',
    txtHash: null,

    showPreviewDialog: false,
    seedTxt: '',
    inferTxt:''
    // seedTxt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mollis, risus ut condimentum egestas, velit neque auctor nisi, sit amet ultrices ipsum elit ut ex. Pellentesque et mattis orci, sit amet posuere ante.',
    // inferTxt: 'Nunc in sapien sit amet leo blandit lobortis. Ut tellus ipsum, mollis ut scelerisque vel, aliquam sed lorem. Duis eu mi purus. Morbi cursus malesuada lorem quis posuere. Ut et tristique tortor, vitae dignissim lorem. Praesent egestas tempor felis, in ullamcorper ipsum sollicitudin et. Phasellus turpis lorem, ullamcorper sed mauris vulputate, faucibus facilisis purus. Aliquam varius venenatis ipsum, id eleifend velit maximus sed. Pellentesque massa lectus, pretium quis nisi placerat, ornare mattis sem. Nunc ut nunc metus. Morbi a purus quis dolor lobortis porta. Nunc a ullamcorper sem, nec porta erat. Nulla quis tempus felis. Donec et nisl purus. Phasellus hendrerit elit a ultricies ultrices. Sed accumsan nunc vitae nibh gravida, lobortis efficitur sapien vehicula. Praesent aliquam sapien bibendum eleifend scelerisque. Proin eget arcu a mi commodo dictum. Duis vel purus tellus. In eu aliquam eros. Donec at condimentum nisi, eget tempor elit.'
    
}

// export const mint = createAsyncThunk(
//     'app/mint',
//     async (seedText, { getState, dispatch }) => {
//         let postData = {
//                 "length": 150,
//                 "forceNoEnd": true,
//                 "prompt": {
//                     "text": seedText
//                 }
//         }
//         await axios.post('https://api.inferkit.com/v1/models/standard/generate', postData, {
//             headers: axiosHeaders
//           })
//           .then((response) => {
//             // dispatch({
//             //   type: FOUND_USER,
//             //   data: response.data[0]
//             // })
//             let txtResponse = response.data.data.text.split('\n\n').join('\n')
//             console.log('axios txtResponse', txtResponse)
//           })
//           .catch((error) => {
//             // dispatch({
//             //   type: ERROR_FINDING_USER
//             // })
//             console.log('axios error', error)
//           })
//     }
// )

export const setGasPrice = createAsyncThunk(
    'app/setGasPrice',
    async (seedText, { getState, dispatch }) => {
        const response = await getGasPrices(seedText)
        return {
            fastGasPrice: response.fast
        }
    }
)

export const generateInferTxt = createAsyncThunk(
    'app/getInferTxt',
    async (seedText, { getState, dispatch }) => {
        const response = await getInferTxtFromAxios(seedText)

        console.log('inferResponse:', response)
        console.log('seedText:', seedText);

        return {
            inferResponse: response,
            seedTxt: seedText
        }
    }
)

export const generateChatGptTxt = createAsyncThunk(
    'app/getChatGptTxt',
    async (params, { getState, dispatch }) => {
        const response = await getChatGptStoryViaApi(params.seedText, params.storyType)

        console.log('chatGptResponse story:', response.story)
        console.log('seedText:', params.seedText);
        console.log('seedText:', params.storyType);

        return {
            chatGptResponse: response,
            seedTxt: params.seedText
        }
    }
)

// export const mintStory = createAsyncThunk(
//     'app/mintStory',
//     async (seedText, { getState, dispatch }) => {
//         const state = getState()
//         console.log('state', state)
//         let seedTxt = state.app.seedTxt
//         let inferTxt = state.app.inferTxt

//         console.log('minting:', seedTxt, inferTxt)

//         await this.props.authorMeERC721Contract.methods.mint(this.props.app.seedTxt, this.props.app.inferTxt)
//             .send({
//                 from: this.props.account,
//                 value: this.props.supplyData._mintPrice
//             })
//             .on('transactionHash', (hash) => {
//                 console.log(`mint transactionHash: ${hash}`)

//                 this.props.showStep3Dialog(hash)
//                 trackGA('Web3', 'Tx-Pending')
//             })
//             .on('error', (err) => {
//                 console.log('mint error:', err)
//             })
//             .then((result) => {
//                 console.log('got mint result:', result)
//                 this.props.showTxSuccessDialog()
//                 trackGA('Web3', 'Web3-Mint-Success')
//             })
//     }
// )

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showWeb3BrowserErrorDialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'Web3 Error'
            state.dialogText = 'Please use a Web3 enabled browser and connect an account.'
            state.txtHash = null
        },
        showWeb3NetworkErrorDialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'Web3 Error'
            state.dialogText = 'Please ensure that you are connected to the Ethereum Main Network.'
            state.txHash = null
        },
        showInputErrorDialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'Input Error'
            state.dialogText = 'You need to provide a starting point for your story - all we need is a few words.'
            state.txHash = null
        },
        showInputLengthError: (state, action) => {
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'Input Length Error'
            state.dialogText = `Please provide at least ${action.payload} characters - this helps the engine build better context, and thus a better story.`
            state.txHash = null
        },
        showStep1Dialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = true
            state.dialogTitle = 'Step1:  Authoring Story'
            state.dialogText = 'Your story is being generated - please hang around. Once your story has been authored you will be able to preview it before minting. This usually takes less than a minute.'
            state.txHash = null
        },
        showStep2Dialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = true
            state.dialogTitle = 'Step 2: Confirm Transaction'
            state.dialogText = "Your story has been generated - go ahead and confirm the transaction! The metaverse is excited to read it!"
            state.txHash = null
        },
        showStep3Dialog: (state, action) => {
            state.showStatusDialog = true
            state.showDialogSpiner = true
            state.dialogTitle = 'Step 3: Transacting Story'
            state.dialogText = `Your transaction has been submitted the to the Ethereum blockchain. This dialog will auto-dismiss when the transaction has been confirmed.`
            state.txHash = action.payload
        },
        showTxSuccessDialog: (state) => {
            state.showPreviewDialog = false

            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = "Boom! You're officially an author. Congrats!"
            state.dialogText = `Your story has been minted. Check out your wallet & OpenSea for the result!`
            state.txHash = null
        },
        showTxRejectedDialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'We feel so...rejected'
            state.dialogText = "We do need to inform you that you're missing a once in a lifetime opertunity - go ahead and hit mint again, but this time click accept  ;)"
            state.txHash = null
        },
        showCatchErrorDialog: (state) => {
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'Oh snap...'
            state.dialogText = `This error really should not be happening. Something is messed-up with the transaction. Please let us know that this happened!`
            state.txHash = null
        },
        dismissDialog: (state) => {
            state.showStatusDialog = false
            state.showDialogSpiner = false
            state.dialogTitle = ''
            state.dialogText = ''
            state.txtHash = null
        },
        dismissPreviewDialog: (state) => {
            trackGA('UX', 'UX-Dismiss-Preview')
            state.showPreviewDialog = false
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(generateInferTxt.pending, (state, action) => {
            console.log('getInferTxt.pending', action.payload)
        })
        .addCase(generateInferTxt.fulfilled, (state, action) => {
            console.log('getInferTxt.fulfilled', action.payload)

            let inferTxt = action.payload.inferResponse.data.text.split('\n\n').join('\n')
            console.log('inferTxt', inferTxt)

            if(inferTxt == null || inferTxt === '' || inferTxt.length < 0) {
                state.showStatusDialog = true
                state.showDialogSpiner = false
                state.dialogTitle = 'Oh snap...'
                state.dialogText = "The response from the authoring engine was invalid. Please let us know that this happened!"
                state.txHash = null

                trackGA('Infer', 'Infer-Request-Invalid-Length')
            }
            else {
                state.showStatusDialog = false
                state.showDialogSpiner = false
                state.dialogTitle = ''
                state.dialogText = ""
                state.txHash = null

                // set elipses
                let inferTxtLastChar = inferTxt.substr(inferTxt.length - 1, 1)
                if(inferTxtLastChar === ',' || inferTxtLastChar === '.' || inferTxtLastChar === ' ') {
                    inferTxt = inferTxt.substr(0, inferTxt.length - 2)
                }
                inferTxt = `${inferTxt}...`

                // xmls safe
                let seedTxt = cleanTextForXml(action.payload.seedTxt)
                inferTxt = cleanTextForXml(inferTxt)
                
                // set state
                state.inferTxt = inferTxt
                state.seedTxt = seedTxt
                state.showPreviewDialog = true

                trackGA('Infer', 'Infer-Request-Success')
            }
        })
        .addCase(generateInferTxt.rejected, (state, action) => {
            console.log('getInferTxt.rejected', action)
            state.showStatusDialog = true
            state.showDialogSpiner = false
            state.dialogTitle = 'Oh snap...'
            state.dialogText = "There was a problem communicating with the authoring engine. This is likely a temporary issue, please try again in a few minutes. Regardless, please let us know that this happened!"
            state.txHash = null

            // trackGA('Infer', 'Infer-Request-API-Error')
        })
        .addCase(generateChatGptTxt.pending, (state, action) => {
            console.log('generateChatGptTxt.pending', action)
        })
        .addCase(generateChatGptTxt.fulfilled, (state, action) => {
            // console.log('generateChatGptTxt.fulfilled', action)
            
            const statusCode = action.payload.chatGptResponse.statusCode
            let seedText = action.payload.seedTxt
            let chatGptTxt = action.payload.chatGptResponse.story

            // capitalize seed text
            // seedText = seedText.charAt(0).toUpperCase() + seedText.slice(1)

            if(statusCode !== 200) {
                state.showStatusDialog = true
                state.showDialogSpiner = false
                state.dialogTitle = 'Oh snap...'
                state.dialogText = "The response from the authoring engine was invalid. Please let us know that this happened!"
                state.txHash = null

                trackGA('ChatGPT', 'Failed API Call')
            }
            else {
                if(chatGptTxt == null || chatGptTxt === '' || chatGptTxt.length < 0) {
                    state.showStatusDialog = true
                    state.showDialogSpiner = false
                    state.dialogTitle = 'Oh snap...'
                    state.dialogText = "The response from the authoring engine was invalid. Please let us know that this happened!"
                    state.txHash = null
    
                    trackGA('ChatGPT', 'ChatGPT-Response-Invalid-Length')
                }
                else {
                    state.showStatusDialog = false
                    state.showDialogSpiner = false
                    state.dialogTitle = ''
                    state.dialogText = ""
                    state.txHash = null

                    // console.log('chatGptTxt:', chatGptTxt);

                    // trim seed text form chatgpt response
                    chatGptTxt = chatGptTxt.substr(seedText.length, chatGptTxt.length - 1)
                    
                    // uppercase 1st char
                    seedText = seedText.charAt(0).toUpperCase() + seedText.slice(1);

                    // set elipses
                    let chatGptTxtLastChar = chatGptTxt.substr(chatGptTxt.length - 1, 1)
                    if(chatGptTxtLastChar === ',' || chatGptTxtLastChar === ' ') {
                        chatGptTxt = chatGptTxt.substr(0, chatGptTxt.length - 2)
                    }
                    chatGptTxt = `${chatGptTxt}...`

                    // xmls safe
                    let seedTxt = cleanTextForXml(seedText)
                    chatGptTxt = cleanTextForXml(chatGptTxt)
                    
                    // set state
                    state.inferTxt = chatGptTxt
                    state.seedTxt = seedTxt
                    state.showPreviewDialog = true

                    trackGA('ChatGPT', 'ChatGPT-Infer-Request-Success')
                }
            }
        })
        .addCase(generateChatGptTxt.rejected, (state, action) => {
            console.log('generateChatGptTxt.rejected', action)
        })
        .addCase(setGasPrice.fulfilled, (state, action) => {
            // console.log('setGasPrice.fulfillde action', action)
            state.fastGasPriceGwei = action.payload.fastGasPrice / 10
        })
    }
})

export const {
    showWeb3BrowserErrorDialog,
    showWeb3NetworkErrorDialog,
    showInputErrorDialog,
    showInputLengthError,
    showStep1Dialog,
    showStep2Dialog,
    showStep3Dialog,
    showTxSuccessDialog,
    showTxRejectedDialog,
    // showInferApiErrorDialog,
    // showInferNullResponseDialog,
    showCatchErrorDialog,
    dismissDialog,
    dismissPreviewDialog
} = appSlice.actions;

export default appSlice.reducer