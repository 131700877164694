export const REACT_GA_ENABLED = true

export const MAX_SEED_CHARS = 300
export const INFER_TXT_LENGTH = 1000

export const INFERKIT_API = 'https://api.inferkit.com/v1/models/standard/generate'
export const AUTHORME_API = 'https://api.authorme.xyz'
export const TWITTER_URL = 'https://twitter.com/authorme_nft'
export const DISCORD_URL = 'https://discord.gg/9gg2yzZ84f'
export const OPENSEA_URL = 'https://opensea.io/collection/author-me'
export const ETHERSCAN_TX_BASE_URL = 'https://etherscan.io/tx/'

export const DEFI_PULSE_API = 'https://data-api.defipulse.com/api/v1/egs/api/ethgasAPI.json?api-key=4b32fc5071723d77ddc92ce658ad8bbcecefdb28cef12db3009db2513c5b'
// export const DEFI_PULSE_API = ' https://ethgasstation.info/api/ethgasAPI.json'

export const GAS_PER_TRANSACTION = 1180000

export function getBackgrondColor() {
    const backgroundColors = ['brand', 'accent-1', 'accent-2', 'status-warning', 'status-critical']
    return backgroundColors[Math.floor(Math.random()*backgroundColors.length)]
}
