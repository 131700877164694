import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import {
    Box,
    Text,
    Card,
    CardBody,
    TextArea,
    Button,
    RadioButtonGroup
} from 'grommet';

import {
    MAX_SEED_CHARS
} from '../../authmeConstants'

import {
    trackGA
}
from '../../utils/miscUtils'

import {
    showInputErrorDialog,
    showInputLengthError,
    showStep1Dialog,
    generateInferTxt,
    generateChatGptTxt
} from '../../redux/appSice'

const styles = theme => ({})

class DappControls extends Component {
    state = {
        seedText: '',
        remainingChars: MAX_SEED_CHARS,
        storyType: 'Bizarre'
    }

    setTextValue = (value) => {
        if(value.length <= MAX_SEED_CHARS) {
            this.setState({
                seedText: value,
                remainingChars: MAX_SEED_CHARS - value.length
            })
        }
    }

    onGenerateStoryClicked = () => {
        if(!this.state.seedText.length || this.state.seedText === '') {
            this.props.showInputErrorDialog()
            trackGA('UX', 'UX-No-Seed-Provided')
            return
        }
        else {
            if(this.state.seedText.length < this.props.supplyData._minSeedLength) {
                this.props.showInputLengthError(this.props.supplyData._minSeedLength)
                trackGA('UX', 'UX-Input-Length-Short')
                return
            }
            else {
                this.props.showStep1Dialog()
                // trackGA('Infer', 'Infer-Request-Started')
                // this.props.generateInferTxt(this.state.seedText)

                trackGA('ChatGPT', 'ChatGPT-Request-Started')
                this.props.generateChatGptTxt({
                    seedText: this.state.seedText,
                    storyType: this.state.storyType
                })
            }       
        }
    }

    onRadionBtnGroupChange = (value) => {
        this.setState({storyType: value})
    }

    renderControlsBox() {
        if(this.props.supplyData) {
            // let price = window.web3.utils.fromWei(this.props.supplyData._mintPrice, 'ether')
            let formattedTotalSupply = this.props.supplyData._totalSupply.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let formattedMaxSupply = this.props.supplyData._maxSupply.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            if(!this.props.supplyData._saleIsActive) {
                return (
                    <Box align='center' gridArea="dapp-controls">
                        <Button primary label='Minting Inactive' color='neutral-3' disabled/>
                    </Box>
                )
            }
            else {
                return (
                    <Box align='center' gridArea="dapp-controls">
                        <RadioButtonGroup
                            margin='large'
                            name="storyType"
                            options={['Bizarre', 'Funny', 'Scary', 'Factual']}
                            value={this.state.storyType}
                            onChange={(event) => this.onRadionBtnGroupChange(event.target.value)}
                        />
                        {
                            this.props.supplyData._totalSupply === this.props.supplyData._maxSupply ?
                                <Button primary label='SOLD OUT' color='neutral-3' size='large' disabled/>
                            :
                                <Button primary label='Generate Story' color='dark-1' size='large' onClick={() => {this.onGenerateStoryClicked()}}/>
                        }
                        <Text size='xsmall'>{parseInt(formattedTotalSupply)}/{formattedMaxSupply} minted</Text>
                    </Box>
                )
            }
        }
        else {
            return (
                <Box align='center' gridArea="dapp-controls">
                    <Text size='medium'>Waiting for supply data...</Text>
                </Box>
            )
        }
    }

    render() {
        // const { classes } = this.props

        return (
            <div>
                <Box margin='small' height='full' align='center' gridArea="dapp-input">
                    <Card height="full" width="medium" background="light-1">
                        <CardBody pad="medium">
                            <TextArea fill={true} size={'xsmall'}
                                value={ this.state.seedText }
                                onChange={event => this.setTextValue(event.target.value)}
                                placeholder='Type the start of your story here...'
                                resize={false}
                            />
                            <Text alignSelf='end' size='xsmall'>{ this.state.remainingChars }</Text>
                        </CardBody>
                    </Card>
                </Box>

                {this.renderControlsBox()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // pathname: state.router.location.pathname,
    // app: state.app,
})
    
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showInputErrorDialog,
    showInputLengthError,
    showStep1Dialog,
    generateInferTxt,
    generateChatGptTxt
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(DappControls))