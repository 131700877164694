import {
    put,
    takeLatest
} from 'redux-saga/effects'

function* mint(action) {
    console.log('mint')
}

export function* mintSaga() {
    yield takeLatest("MINT", mint)
}